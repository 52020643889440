import React from 'react';

const Facebook = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="16.122"
    height="30.872"
    viewBox="0 0 16.122 30.872"
  >
    <path
      d="M90.462,30.872V16.808h4.8l.686-5.488H90.462V7.89c0-1.544.515-2.744,2.744-2.744h2.916V.172c-.686,0-2.4-.172-4.288-.172C87.718,0,84.8,2.573,84.8,7.2V11.32H80v5.488h4.8V30.872Z"
      transform="translate(-80)"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default Facebook;
